<template>
  <div
      class="flex flex-col w-full text-a-neutral relative font-sans tracking-wide"
      id="page_layout"
  >
    <Transition name="fade">
      <FundraisingHomeNav />
    </Transition>
    <Transition name="fade">
      <MainSideMenuMobile class="flex md:hidden"/>
    </Transition>
    <Transition name="fade">
      <FundraisingNav v-show="showStickyNavBar == true" />
    </Transition>
    <div class="flex flex-col">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">

const showStickyNavBar =  ref(false);

function handleScroll() {
  useNuxtApp().getScrollVerticalPositionInPixels() > 545
      ? (this.showStickyNavBar = true)
      : (this.showStickyNavBar = false);
}

function hasReachedBottom() {
  useNuxtApp().hasReachedBottom();
}

onMounted(()=>{
  nextTick(function () {
  //  useNuxtApp().addScrollListener(this.handleScroll);
  });
})

onBeforeUnmount(()=>{
  //useNuxtApp().removeScrollListener(this.handleScroll);
})
</script>

<style></style>
