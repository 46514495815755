<template>
  <div class="flex flex-row w-full bg-black bg-opacity-40 h-auto text-white">
    <div
        class="flex flex-row items-center justify-evenly w-full max-w-screen-xl mx-auto h-full"
    >
      <Icon
          @click="drawerStore.openDrawer()"
          name="streamline:interface-setting-menu-2-button-parallel-horizontal-lines-menu-navigation-staggered-three-hamburger"
          class="w-2/12 text-2xl mx-2"
      />
      <!-- Brand Starts -->
      <nuxt-link
          :to="{ name: 'fundraising' }"
          class="flex flex-col pl-2 items-start justify-center cursor-pointer w-6/12"
      >
        <NuxtImg
            src="/images/main/logo_inv.png"
            alt=""
            class="h-10 w-24"
        />
        <p class="text-sm mx-auto font-semibold tracking-widest">Fundraising</p>
      </nuxt-link>
      <BaseButton
          margin-classes="mx-2"
          primary-color-class="a-primary"
          hovered-text-color-class="a-primary"
          text-size-class="text-sm"
          width-class="w-48"
          text-color-class="hover:text-a-neutral-darkest"
          is-outlined
          @click.native="gotoCreateNewFundraisingCampaign"
      >
        <Icon name="mdi:plus"/>
        New Fundraiser
      </BaseButton>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
const drawerStore = useDrawer();

function gotoCreateNewFundraisingCampaign() {
  navigateTo({
    name: "dashboard-fundraising-create-new",
  });
}

const isLoggedIn = computed(() => {
  return useAuthManager().isLoggedIn.value;
});
</script>

<style></style>
