<template>
  <div
      class="swiper fundraisingMainCarousel w-full h-96 md:h-128"
  >
    <div class="swiper-wrapper">
      <div
          class="swiper-slide font-semibold text-2xl text-center w-full"
          v-for="banner in banners"
          :key="banner"
      >
        <NuxtImg
            :src="banner"
            alt=""
            class="w-full h-96 md:h-128 object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>

import {Autoplay, EffectFade} from "swiper/modules";

const banners = ref([
  "/images/fundraising/main_1.jpg",
  "/images/fundraising/main_2.jpg",
  "/images/fundraising/main_3.jpg",
  "/images/fundraising/main_4.jpg",
]);

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.fundraisingMainCarousel', {
    modules: [Autoplay, EffectFade],
    direction: "horizontal",
    slidesPerView: "1",
    autoplay: {
      delay: 5000,
    },
    grabCursor: true,
    loop: true,
    effect: "fade",
    allowTouchMove: false,
    observer: true,
  });
})
</script>

<style></style>
